<template>
  <label class="relative flex flex-1 flex-col pb-8">
    <div
      class="mb-1 flex justify-between text-sm"
      :class="{ hidden: hiddenLabel || !label }"
    >
      <span class="font-semibold">{{ label }}</span>
      <span v-if="labelDescription">
        {{ labelDescription }}
      </span>
    </div>
    <div class="flex" :class="{ 'xs:flex-col sm:flex-row': oneColumnMobile }">
      <div class="relative flex h-12 flex-auto">
        <input
          ref="input"
          v-bind="$attrs"
          :name="name"
          :value="value"
          class="input-arrows-hidden w-full flex-1 rounded-md border pl-2 placeholder:text-black/[0.26]"
          :class="{
            'border-supporting-dark-4': valid,
            'border-supporting-dark-2': computedErrorMessage,
          }"
          :placeholder="placeholder"
          v-on="validationListeners"
        />
        <slot name="inlinePictogram">
          <span v-show="valid" class="absolute right-0 top-0 flex h-full p-1">
            <DynamicPictogram
              class="p-2 text-supporting-dark-4"
              name="check-mark"
              size="lg"
            />
          </span>
        </slot>
      </div>
      <slot name="button"></slot>
    </div>
    <p class="absolute bottom-2 mt-0 text-sm text-supporting-dark-2">
      {{ computedErrorMessage }}
    </p>
  </label>
</template>

<script setup lang="ts">
import { useField } from 'vee-validate';

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  rules: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  hiddenLabel: {
    type: Boolean,
    default: false,
  },
  oneColumnMobile: {
    type: Boolean,
    default: false,
  },
  labelDescription: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue', 'blur:valid']);

const input = ref();
defineExpose({
  input,
});
const { rules, modelValue, name } = toRefs(props);

const attrs = useAttrs();

const validationRule = rules.value ? rules.value : undefined;

const { errorMessage, meta, handleChange, value } = useField(
  name,
  validationRule,
  {
    initialValue: modelValue,
    validateOnValueUpdate: false,
    syncVModel: true,
  }
);

const valid = computed(() => props.rules && meta.valid && meta.touched);

const onBlur = (e: InputEvent) => {
  handleChange(e, !!errorMessage.value);
  emit('blur:valid', props.rules && meta.valid);
};

const validationListeners = computed(() =>
  // If the field is valid or have not been validated yet
  // disable `shouldValidate` to avoid validating on input
  ({
    blur: (e: InputEvent) => onBlur(e),
    change: handleChange,
    input: (e: InputEvent) => handleChange(e, !!errorMessage.value),
  })
);

const computedErrorMessage = computed(() => props.error || errorMessage.value);

onMounted(() => {
  setTimeout(() => {
    if (
      input.value &&
      Object.keys(attrs).includes('autofocus') &&
      attrs.autofocus !== false
    ) {
      input.value.focus();
    }
  }, 50);
});
</script>

<style lang="postcss"></style>
